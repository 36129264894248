import "../scss/app.scss";
import Vue from "vue";
import "bootstrap";
const carousels = require("./carousels");
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const app = new Vue({
  el: "#app",
  data: {
    isToggleMenu: false,
  },
  mounted() {
    this.InView();
    carousels.carouselSingle();
    carousels.carouselSingleThumbnail();
    carousels.carouselCentering();
    carousels.carouselSideScrolling();
    carousels.carouselCover();
  },
  methods: {
    InView() {
      const trigger = document.querySelectorAll("[data-trigger]");
      trigger.forEach(function(userItem) {
        ScrollTrigger.create({
          trigger: userItem,
          start: "top bottom-=50px",
          end: "bottom top+=50px",
          toggleClass: { targets: userItem, className: "InView" },
          once: true,
        });
      });
    },
  },
});